<template>
  <v-container grid-list-xl fluid>
    <v-row class="mb-2 mb-md-4 mb-lg-6 text-center">
      <v-col cols="12">
        <!-- <v-select
                    label="ESFM/UA"
                    v-model="institucion_id"
                    :items="instituciones"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'Seleccione una opción']"
                    filled
                  ></v-select> -->

        <h3 class="text-h6 text-md-h5 text-lg-h3 mt-1 mt-md-1 mt-lg-2">
          CONVOCATORIA PÚBLICA ESFM/UA - N° 002/2023
        </h3>
        <p class="text-subtitle-1 text-md-h5 text-lg-h4 mt-1 mt-md-2 mt-lg-3">
          COMPULSA DE MÉRITOS PROFESIONALES PARA OPTAR A CARGOS DIRECTIVOS, 
          DOCENTES, ADMINISTRATIVOS Y DE SERVICIO EN LAS ESCUELAS SUPERIORES DE
          FORMACIÓN DE MAESTRAS Y MAESTROS Y UNIDADES ACADÉMICAS DEL
          ESTADO PLURINACIONAL DE BOLIVIA GESTIÓN 2023
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#B2EBF2" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Resultados a Cargos Convocados (ESFM/UA)
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/resultados002_esfm.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#D1C4E9" tile darks>
              <v-card-title class="purple--text text--darken-4 text-h6">
                CONVOCATORIA PÚBLICA ESFM/UA - N° 002/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                27/01/2023 
              </v-card-subtitle>
              <v-card-text>
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/convocatoria_002.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col cols="12" md="4" sm="6">
          <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/EGI N° 004/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                31/01/2023 - ACLARATORIA DE SEDE ESFM "RIBERALTA" PROCESO DE COMPULSA DE MÉRITOS - ESFM/UA  
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/Comunicado_DGFM_EGI_No004_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card> 
      </v-col>
    
     
     
    </v-row>
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Anexo 3: SEDES DE LA CALlFICACION DE MERITOS PROFESIONALES
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                31/01/2023
              </v-card-subtitle>
              <v-card-text>
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/anexo3_002.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Anexo 2: REQUISITOS INDISPENSABLES DE CARGOS CONVOCADOS
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
               
              </v-card-subtitle>
              <v-card-text>
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/anexo2_002.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Anexo 1: Cargos convocados
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                27/01/2023
              </v-card-subtitle>
              <v-card-text>
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/anexo1_002.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
<br/>
<hr/>
    <v-row class="mb-2 mb-md-4 mb-lg-6 text-center">
      <v-col cols="12">
        <h3 class="text-h7 text-md-h6 text-lg-h5 mt-1 mt-md-1 mt-lg-2">
          CONVOCATORIA PÚBLICA ESFM/UA - N° 001/2023
        </h3>
        <p class="text-subtitle-1 text-md-h6 text-lg-h5 mt-1 mt-md-2 mt-lg-3">
          COMPULSA DE MÉRITOS PROFESIONALES PARA OPTAR A CARGOS DIRECTIVOS, 
          DOCENTES, ADMINISTRATIVOS Y DE SERVICIO EN LAS ESCUELAS SUPERIORES DE
          FORMACIÓN DE MAESTRAS Y MAESTROS Y UNIDADES ACADÉMICAS DEL
          ESTADO PLURINACIONAL DE BOLIVIA GESTIÓN 2023
        </p>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#FBE9E7" tile darks>
              <v-card-title class="deep-orange--text text--darken-4 text-h6">
                Resultados de cargos convocados
              
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/resultado_1_22.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#FBE9E7" tile darks>
              <v-card-title class="deep-orange--text text--darken-4 text-h6">
                Nómina de postulantes
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/nomina_postulantes.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#D1C4E9" tile darks>
              <v-card-title class="purple--text text--darken-4 text-h6">
                Resultados a Cargos Convocados
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/resultados_esfm.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/EGI N° 003/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                ACLARATORIA DE SEDES PARA LOS DEPARTAMENTOS DE PANDO Y BENI, PROCESO DE COMPULSA DE MÉRITOS - ESFM/UA
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/Comunicado_DGFM_EGI_No003_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Anexo 3: Sedes de la calificación de méritos profesionales
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/Anexo_3_SEDES_CONV_ESFM_1_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="3" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#B2EBF2" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Anexo 3: Sedes
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/anexo3_compulsa_2022.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row >
     
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#D1C4E9" tile darks>
              <v-card-title class="purple--text text--darken-4 text-h6">
                Convocatoria
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/convocatoria_compulsa_001_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      
     
     
      <!-- <v-col cols="12" sm="4">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Formulario N° 3
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/formulario_3.doc"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> -->
      
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/EGI N° 002/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                REFERENTE A FECHAS PARA EL PROCESO DE COMPULSA DE MÉRITOS  - ESFM/UA
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/COMUNICADO_DGFM_EGI_002_23.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Anexo 2: REQUISITOS INDISPENSABLES DE CARGOS CONVOCADOS
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/anexo2_compulsa_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row> 
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#D1C4E9" tile darks>
              <v-card-title class="purple--text text--darken-4 text-h6">
                Certificación
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Certificación para el personal de las ESFM/UA.</v-card-subtitle
              >
              <v-card-text>
                Certificado de No contar con procesos administrativos con Resolución Final Sancionatoria,.
              </v-card-text>
              <v-card-actions>
                <v-btn
                  outlined
                  color="#546E7A"
                  :to="{ name: 'compulsa-certificado' }"
                >
                  Ir al formulario
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col> 
      
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#FFEBEE" tile>
              <v-card-title class="red--text text--darken-1">
                Comunicado DGFM/EGI N° 001/2023
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                ASPECTOS IMPORTANTES
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/COMUNICADO_DGFM_EGI_001_23.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text>
            <v-card color="#DCEDC8" tile darks>
              <v-card-title class="cyan--text text--darken-4 text-h6">
                Anexo 1: Cargos convocados
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/ANEXO_1_CARGOS_CONVOCADOS_ESFM_CONV_01_2023.pdf"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
  </v-row> 
  <v-row >
    <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-card-text class="grey--text text--darken-4">
            <v-card color="#D1C4E9" tile darks>
              <v-card-title class="purple--text text--darken-4 text-h6">
                Formulario N° 4, Declaración Jurada – Doble Percepción
              </v-card-title>
              <v-card-subtitle class="grey--text text--darken-3">
                Ver o descargar haciendo clic en el siguiente botón
              </v-card-subtitle>
              <v-card-actions>
                <v-btn
                  color="#546E7A"
                  outlined
                  href="//dgfmapi.minedu.gob.bo/compulsa/FORMULARIO_4_DECLARACION_JURADA_DOBLE_PERCEPCION.docx"
                  target="_blank"
                  download
                >
                  Descargar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
</v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title>Nota importante</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>
              Para usar el sistema se recomienda utilizar los siguientes
              navegadores web, a razón del uso de las nuevas tecnologías.
            </p>
            <p>Navegadores:</p>
            <div class="pl-5">
              <a href="https://www.google.com/intl/es/chrome/" target="_blank">
                <b>Chrome</b> en su ultima preferentemente
              </a>
              <br />
              <a
                href="https://www.mozilla.org/es-ES/firefox/new/"
                target="_blank"
              >
                <b>Firefox</b> en su ultima preferentemente
              </a>
              <br />
              <a href="https://www.opera.com/es" target="_blank">
                <b>Opera</b> en su ultima preferentemente
              </a>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "compulsa-documento",
  data: () => ({
    instituciones: [
      {id: 1,descripcion: "aaaaaa"},
      {id: 2,descripcion: "bbbbbbb"},
    ],
    institucion_id:"",
  }),
  mounted() {},
  methods: {},
};
</script>